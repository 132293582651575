import { setTimeout } from 'timers/promises';
import { type ChangeEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePrivy } from '@privy-io/react-auth';
import { captureException } from '@sentry/react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';
import { faAngleLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { mixpanelClient } from '../../clients/mixpanelClient';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { invalidateOperations, useMutation } from '../../graphql/client';
import { AuthUserDocument, UpdateUserOnboardingDocument } from '../../graphql/generated';
import { useFreeTier } from '../../hooks/useFreeTier';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { removeInvalidUsernameChars, usernameSchema } from '../../utils/username';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { FormErrorIndicator } from '../forms/FormErrorIndicator';
import { Logo } from '../svg/Logo';
import { SimplifiedAllAccessPass } from '../vault/AllAccessPass';
import { StaticMembershipBadge } from '../vault/MembershipBadge';

export function MembershipView({
  isLoading,
  artistName,
  serialNumber,
  imageUrl,
  displayName,
  createdAt,
  receiptCount,
  children,
  artistHandle,
  className,
}: {
  isLoading: boolean;
  artistName: string;
  serialNumber: number | null | undefined;
  imageUrl: string | null | undefined;
  displayName: string | null | undefined;
  createdAt: string | undefined;
  receiptCount: number | undefined;
  children?: React.ReactNode;
  artistHandle: string;
  className?: string;
}) {
  return (
    <>
      <StaticMembershipBadge
        isLoading={isLoading}
        artistName={artistName}
        serialNumber={serialNumber}
        imageUrl={imageUrl}
        displayName={displayName}
        createdAt={createdAt}
        receiptCount={receiptCount}
        artistHandle={artistHandle}
        className={className}
      />
      {children}
    </>
  );
}

const validationSchema = z.object({
  newUsername: usernameSchema,
  optIn: z.boolean().optional(),
});
type ValidationSchema = z.infer<typeof validationSchema>;

export function MembershipConfirmationView({
  vaultId,
  isLoading,
  artist,
  loggedInUserUsername,
  loginStatus,
  inviteCode,
  smsCampaignResponseShortcode,
  sourceReleaseCampaignId,
  onComplete,
  isModal = false,
  className,
  withVaultTheme,
}: {
  vaultId: string | null | undefined;
  isLoading: boolean;
  artist:
    | {
        linkValue: string;
        name: string;
        membershipImageUrl: string | null | undefined;
      }
    | null
    | undefined;
  loggedInUserUsername: string | null | undefined;
  loginStatus: LoginStatus;
  inviteCode: string | null | undefined;
  smsCampaignResponseShortcode: string | null | undefined;
  sourceReleaseCampaignId: string | null | undefined;
  onComplete: () => void;
  isModal?: boolean;
  className?: string;
  withVaultTheme: boolean;
}) {
  const { mutateAsync } = useMutation(UpdateUserOnboardingDocument, {});
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [currentNewUsername, setCurrentNewUsername] = useState(loggedInUserUsername ?? 'username');
  const [showUsernameInput] = useState(!loggedInUserUsername);
  const { openBottomsheet } = useBottomsheetContainer();
  const { logout } = usePrivy();
  const navigate = useNavigate();

  const defaultOptIn = true;
  const [optIn, setOptIn] = useState(defaultOptIn);

  const { subscribeFreeTier } = useFreeTier();

  const randomSerialId = useMemo(() => Math.floor(Math.random() * 1000), []);
  const now = useMemo(() => new Date().toISOString(), []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    setError,
  } = useForm<ValidationSchema>({
    defaultValues: {
      newUsername: '',
      optIn: defaultOptIn,
    },
    resolver: zodResolver(validationSchema),
  });

  const onLogoutClick = () => {
    if (isModal) return;

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CONFIRMATION,
      confirmationBottomsheetProps: {
        subText: 'Are you sure you want to logout?',
        onConfirm: async () => {
          trackEvent({ type: EVENTS.SIGN_OUT, properties: null });
          mixpanelClient.reset();
          await logout();
          navigate(ROUTES.SIGN_IN);
        },
      },
    });
  };

  useEffect(() => {
    if (!defaultOptIn) return;

    if (optIn) return;

    setOptIn(true);
    setValue('optIn', true);
  }, [defaultOptIn, setOptIn, optIn, setValue]);

  useEffect(() => {
    if (loggedInUserUsername != null) {
      setValue('newUsername', loggedInUserUsername);
    }
  }, [loggedInUserUsername, setValue]);

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  const onChangeUsername: ChangeEventHandler<HTMLInputElement> = e => {
    if (isSubmitting) return;
    const newUsername = removeInvalidUsernameChars(e.target.value.toLowerCase().trim());
    setValue('newUsername', newUsername);
    setCurrentNewUsername(newUsername);
  };

  const errorText = errors.newUsername?.message;

  const attemptNumber = useRef(0);

  const onSubmit: SubmitHandler<ValidationSchema> = async (data, e) => {
    attemptNumber.current++;
    e?.preventDefault();

    if (artist && !data.optIn) {
      setError('optIn', {
        message: 'You must agree to continue',
      });
      return;
    }

    if (!showUsernameInput) {
      setIsFormLoading(true);

      vaultId &&
        (await subscribeFreeTier({
          input: {
            vaultId,
            inviteCode,
            smsCampaignResponseShortcode,
            sourceReleaseCampaignId,
          },
        }));

      onComplete();
      return;
    }

    const newUsername = data.newUsername.trim().toLowerCase();

    if (newUsername.length < 3) {
      setError('newUsername', {
        message: 'Username must be at least 3 characters',
      });
      trackEvent({
        type: EVENTS.SET_USERNAME_ERROR,
        properties: {
          artistHandle: null,
          error_type: 'invalid_format',
          username: newUsername,
          attempt_num: attemptNumber.current,
        },
      });
      return;
    }

    try {
      setIsFormLoading(true);

      const result = await mutateAsync({ input: { username: newUsername, unset: {} } });

      if (result.data.updateUserProfile.__typename === 'MutationUpdateUserProfileSuccess') {
        mixpanelClient.people.set({ name: newUsername });

        trackEvent({
          type: EVENTS.SET_USERNAME_SUCCESS,
          properties: {
            artistHandle: null,
            attempt_num: attemptNumber.current,
          },
        });
        attemptNumber.current = 0;

        await invalidateOperations({ operations: [AuthUserDocument] });

        if (vaultId) {
          await subscribeFreeTier({
            input: {
              vaultId,
              inviteCode,
              smsCampaignResponseShortcode,
              sourceReleaseCampaignId,
            },
          });
        }
        onComplete();
      } else if (result.data.updateUserProfile.__typename === 'ValidationError') {
        setIsFormLoading(false);
        setError('newUsername', {
          message: 'Only lowercase letters, numbers, hyphens, and underscores',
        });
        trackEvent({
          type: EVENTS.SET_USERNAME_ERROR,
          properties: {
            artistHandle: null,
            error_type: 'invalid_format',
            username: newUsername,
            attempt_num: attemptNumber.current,
          },
        });
        return;
      } else if (result.data.updateUserProfile.__typename === 'UsernameUnavailableError') {
        setIsFormLoading(false);
        setError('newUsername', {
          message: 'Username is not available, please try again',
        });
        trackEvent({
          type: EVENTS.SET_USERNAME_ERROR,
          properties: {
            artistHandle: null,
            error_type: 'username_taken',
            username: newUsername,
            attempt_num: attemptNumber.current,
          },
        });
      } else {
        setError('newUsername', {
          message: 'An error occurred while updating your username',
        });
        trackEvent({
          type: EVENTS.SET_USERNAME_ERROR,
          properties: {
            artistHandle: null,
            error_type: 'unknown',
            username: newUsername,
            attempt_num: attemptNumber.current,
          },
        });
      }
    } catch (e) {
      setIsFormLoading(false);
      setError('newUsername', {
        message: 'An error occurred while updating your username',
      });
      captureException(e, {
        extra: {
          newUsername,
        },
      });
      trackEvent({
        type: EVENTS.SET_USERNAME_ERROR,
        properties: {
          artistHandle: null,
          error_type: 'unknown',
          username: newUsername,
          attempt_num: attemptNumber.current,
        },
      });
      throw e;
    }
  };

  if (artist) {
    return (
      <div
        className={twMerge(
          'w-full overflow-hidden bg-vault_background',
          withVaultTheme ? 'bg-vault_background' : 'bg-black',
          isModal ? 'h-fit  md2:min-w-[390px]' : 'h-screen',
          className,
        )}
      >
        <div
          className={twMerge(
            'flex flex-col items-center justify-center md2:mx-auto md2:px-0',
            isModal ? 'h-fit md2:max-w-md' : 'h-screen px-4 md2:max-w-lg',
          )}
        >
          <View
            className={twMerge(
              'mt-4 box-content flex w-full flex-col items-center justify-center md:mt-0',
              isModal ? '' : 'md:mx-6',
            )}
          >
            <MembershipView
              isLoading={isLoading}
              artistName={artist.name}
              serialNumber={randomSerialId}
              imageUrl={artist.membershipImageUrl}
              displayName={currentNewUsername}
              createdAt={now}
              receiptCount={0}
              artistHandle={artist.linkValue}
              className="sm:w-full md2:w-full"
            />
          </View>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="box-content flex w-full flex-col gap-[10px] pb-[20px] pt-7"
          >
            <View className="flex flex-col gap-2">
              <Text
                className={twMerge(
                  'text-center font-title text-[20px]',
                  withVaultTheme ? 'text-vault_text' : 'text-white',
                )}
              >
                Complete your membership
              </Text>
              <Text
                className={twMerge(
                  'text-center font-base text-[16px] font-normal leading-[21px]',
                  withVaultTheme ? 'text-vault_text/70' : 'text-white/70',
                )}
              >
                I'll add you to my contacts
                <br />
                so we can chat via text.
              </Text>
            </View>

            <SimplifiedAllAccessPass artistHandle={artist.linkValue} />

            <View className="flex flex-col gap-4">
              {showUsernameInput && (
                <View className="box-content flex flex-col gap-2 sm:mx-6">
                  <View className="relative flex flex-row items-center gap-4">
                    <input
                      type="text"
                      {...register('newUsername')}
                      placeholder="Enter username"
                      onChange={onChangeUsername}
                      className={twMerge(
                        'flex-1 rounded-md border border-solid p-3 font-base text-[16px]/[20px] focus:outline-none',
                        withVaultTheme
                          ? 'border-vault_text/15 text-vault_text placeholder:text-vault_text/50'
                          : 'border-white/15 text-white placeholder:text-white/50',
                      )}
                      maxLength={20}
                      onFocus={async e => {
                        await setTimeout(100);
                        e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
                      }}
                    />
                    {errorText != null && (
                      <View className="absolute right-3 mt-1">
                        <FormErrorIndicator />
                      </View>
                    )}
                  </View>
                  {errorText != null && (
                    <Text className="text-center font-base text-[14px]/[18px] font-normal text-destructive300">
                      {errorText}
                    </Text>
                  )}
                </View>
              )}

              {errors.optIn && (
                <Text className="text-center font-base text-[14px]/[18px] font-normal text-destructive300">
                  You must agree to continue
                </Text>
              )}
            </View>

            <div className="bg-dstructive50 flex w-full justify-center">
              <div className="mx-6 pt-6">
                <Button
                  label={!isFormLoading ? 'Join' : null}
                  type={withVaultTheme ? 'primary-themed' : 'primary'}
                  buttonType="submit"
                  loading={isFormLoading}
                  iconOnly={isFormLoading}
                  disabled={isFormLoading || optIn === false}
                  disabledClassName="opacity-50 cursor-not-allowed"
                  event={{ type: EVENTS.NEXT, properties: { type: 'Onboarding Username' } }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={twMerge(
          'w-full overflow-hidden',
          withVaultTheme ? 'bg-vault_background' : 'bg-black',
          isModal ? 'h-fit' : 'h-screen',
        )}
      >
        <div
          className={twMerge(
            'flex flex-col items-center px-6 md2:mx-auto md2:max-w-lg md2:px-0',
            isModal
              ? 'h-fit justify-center'
              : 'h-screen justify-start pt-10 md2:justify-center md2:pt-0',
          )}
        >
          {!isModal && (
            <View className="relative mb-5 flex w-full flex-row items-center justify-center">
              <Button
                iconOnly
                label="Back"
                leadingIcon={faAngleLeft}
                className={twMerge(
                  'absolute left-0 h-[32px] w-[32px] select-none text-[24px] text-white outline-none md:w-[32px]',
                  withVaultTheme && 'text-vault_text',
                )}
                onClick={onLogoutClick}
                event={{ type: EVENTS.BACK, properties: { type: 'default' } }}
              />

              <Logo
                className="h-12 w-12 flex-shrink-0"
                variant={withVaultTheme ? 'themed' : 'default'}
              />
            </View>
          )}
          <Text
            className={twMerge(
              'mb-5 font-title text-[28px]/[34px] font-normal',
              withVaultTheme ? 'text-vault_text' : 'text-white',
            )}
          >
            Choose a username
          </Text>
          <div className="mb-5 flex h-1 w-[168px] flex-row gap-2">
            <div
              className={twMerge(
                'flex h-1 w-40 rounded-full',
                withVaultTheme ? 'bg-yellow100' : 'bg-white',
              )}
            />
          </div>
          <Text
            className={twMerge(
              'mb-8 text-center font-base text-[16px]/[20px] font-normal',
              withVaultTheme ? 'text-vault_text/70' : 'text-white/70',
            )}
          >
            Your username is public and is how you
            <br />
            will appear to artists and members.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <View className="mx-6 my-3 box-content flex flex-col">
              <View className="mx-5 flex flex-row items-center gap-4">
                <input
                  type="text"
                  {...register('newUsername')}
                  placeholder="Username"
                  onChange={onChangeUsername}
                  className={twMerge(
                    'flex-1 border-none bg-transparent font-base text-[16px]/[20px] font-normal text-white focus:border-none focus:outline-none',
                    withVaultTheme ? 'text-vault_text' : 'text-white',
                  )}
                  maxLength={20}
                />
                {errorText != null && <FormErrorIndicator />}
              </View>
              <View
                className={twMerge(
                  'mt-3 h-[1px] w-full',
                  withVaultTheme ? 'bg-vault_text/15' : 'bg-white/15',
                  errorText != null && 'bg-destructive300',
                )}
              />
              {errorText != null && (
                <Text className="mt-3 text-center font-base text-[14px]/[18px] font-normal text-destructive300">
                  {errorText}
                </Text>
              )}
            </View>
            <div className="bg-dstructive50 mb-5 mt-10 flex w-full justify-center">
              <div className="mx-6">
                <Button
                  label={!isFormLoading ? 'Done' : null}
                  type={withVaultTheme ? 'primary-themed' : 'primary'}
                  buttonType="submit"
                  loading={isFormLoading}
                  iconOnly={isFormLoading}
                  disabled={isSubmitting}
                  disabledClassName="opacity-50 cursor-not-allowed"
                  event={{ type: EVENTS.NEXT, properties: { type: 'Onboarding Username' } }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
