import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import * as Slider from '@radix-ui/react-slider';
import { debounce } from 'lodash-es';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';
import { seek } from '../../audio/AudioController';
import { load, pause, play, useAudioEngineHTML5 } from '../../audio/AudioEngineHTML5';
import { queryAudioFromTrack } from '../../audio/AudioFetcher';
import { useAudioPosition } from '../../audio/AudioPosition';
import { BAR_MARGIN, BAR_WIDTH } from '../../constants/waveformConstants';
import {
  type FragmentType,
  getFragment,
  WaveformTrackInfoFragmentDoc,
} from '../../graphql/generated';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { getDurationAsTime } from '../../utils/dateUtils';
import { paintCanvas, waveformAvgChunker } from '../../utils/waveformUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';

// This is necessary so that the rendered peak are not blurry
const RESOLUTION_MULTIPLIER = 4;

export const SNIPPET_DURATION = 25;

export const MINIMUM_SNIPPET_DURATION = 15;
export const MAXIMUM_SNIPPET_DURATION = 60;

const SNAP_WIDTH = BAR_WIDTH + BAR_MARGIN;

const HEIGHT = 56;

export const SnippetWaveform = memo(
  ({
    className,
    track,
    initialSnippetStart,
    onTrim,
    initialSnippetEnd = initialSnippetStart + SNIPPET_DURATION,
  }: {
    track: FragmentType<WaveformTrackInfoFragmentDoc>;
    className?: string;
    initialSnippetStart: number;
    initialSnippetEnd?: number;
    onTrim: ({ start, end }: { start: number; end: number }) => void;
  }) => {
    const vaultTheme = useSnapshot(VaultThemeStore);

    const {
      normalizedPeaks,
      id: trackId,
      duration,
    } = getFragment(WaveformTrackInfoFragmentDoc, track);

    const durationMinusMINDURATION = Math.max(duration - MINIMUM_SNIPPET_DURATION, 0);

    const { playing, ready } = useAudioEngineHTML5();
    const { position: currentAudioPosition } = useAudioPosition();

    const [snippetStart, setSnippetStart] = useState(initialSnippetStart);

    const isSongShorterThanSnippet =
      Math.max(initialSnippetEnd - initialSnippetStart, MINIMUM_SNIPPET_DURATION) >= duration;

    const [snippetEnd, setSnippetEnd] = useState(initialSnippetEnd);

    const [waveformWidth, setWaveformWidth] = useState(0);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const startPercentage = ((snippetStart / duration) * 100 || 0) / 100;
    const startBarNum = Math.floor((startPercentage * waveformWidth) / SNAP_WIDTH);

    const endPercentage = ((snippetEnd / duration) * 100 || 0) / 100;
    const endBarNum = Math.floor((endPercentage * waveformWidth) / SNAP_WIDTH);

    const chunkedPeaks = waveformAvgChunker(
      normalizedPeaks,
      Math.min(Math.floor(waveformWidth / SNAP_WIDTH), normalizedPeaks.length),
    );

    const paintWaveformCanvas = useCallback(() => {
      if (!canvasRef.current) {
        return;
      }

      paintCanvas({
        canvasRef,
        normalizedPeaks: chunkedPeaks,
        maxBarHeight: HEIGHT * RESOLUTION_MULTIPLIER,
        barWidth: BAR_WIDTH * RESOLUTION_MULTIPLIER,
        barMargin: BAR_MARGIN * RESOLUTION_MULTIPLIER,
        playingBarNum: 0,
        hoverXCoord: 0,
        isActiveTrack: true,
        showHalf: true,
        snippet: {
          start: startBarNum,
          end: endBarNum,
        },
        customActiveColor: vaultTheme.accentColor,
      });
    }, [chunkedPeaks, endBarNum, startBarNum, vaultTheme.accentColor]);

    useEffect(() => {
      paintWaveformCanvas();
    }, [paintWaveformCanvas]);

    useEffect(() => {
      async function loadTrack() {
        const songInfo = await queryAudioFromTrack({
          trackId,
          editSnippet: true,
        });

        if (!songInfo?.cdnUrl) return;

        load({ autoplay: false, src: songInfo.cdnUrl });
      }

      loadTrack();
    }, [trackId]);

    useEffect(() => {
      const handleResize = debounce(() => {
        if (containerRef.current) {
          setWaveformWidth(containerRef.current.offsetWidth);
        }
      }, 200);

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }, [containerRef]);

    useEffect(() => {
      if (!playing || !ready) return;

      if (currentAudioPosition >= snippetEnd) {
        pause();
      }
    }, [currentAudioPosition, playing, ready, snippetEnd]);

    return (
      <>
        <View className="relative h-full w-full">
          <View className={twMerge('w-full', className)} containerRef={containerRef}>
            <canvas
              className="display-block w-full cursor-default"
              style={{
                height: HEIGHT,
                WebkitTapHighlightColor: 'transparent',
              }}
              ref={canvasRef}
              height={HEIGHT * RESOLUTION_MULTIPLIER}
              width={waveformWidth * RESOLUTION_MULTIPLIER}
            />
          </View>
          <View className="absolute bottom-5 right-[2px] h-1 w-full bg-vault_text/5" />

          <View className="absolute -bottom-2 flex w-full items-center justify-between">
            <Text className="font-base !text-base-m font-normal text-vault_text">
              {getDurationAsTime(snippetStart)}
            </Text>
            <Text className="font-base !text-base-m font-normal text-vault_text">
              {getDurationAsTime(snippetEnd)}
            </Text>
          </View>
        </View>

        <View className="absolute bottom-[18px] w-full cursor-pointer">
          <Slider.Root
            className="relative flex h-[40px] w-full touch-none select-none items-center bg-transparent"
            value={[snippetStart, snippetEnd]}
            max={duration}
            minStepsBetweenThumbs={15}
            min={0}
            step={1}
            disabled={isSongShorterThanSnippet}
            onPointerUp={async () => {
              seek(snippetStart);
              play({ forcePlay: true });

              onTrim({ start: snippetStart, end: snippetEnd });
            }}
            onValueChange={values => {
              const computedSnippetStart = values[0];
              const computedSnippetEnd = values[1];

              if (computedSnippetStart != null && computedSnippetEnd != null) {
                let startValue = Math.min(
                  Math.max(computedSnippetStart, 0),
                  durationMinusMINDURATION,
                );

                const endValue = Math.min(
                  Math.max(computedSnippetEnd, MINIMUM_SNIPPET_DURATION + startValue),
                  startValue + MAXIMUM_SNIPPET_DURATION,
                  duration,
                );

                if (endValue !== computedSnippetEnd) {
                  if (computedSnippetEnd > snippetEnd) {
                    startValue = computedSnippetEnd - MAXIMUM_SNIPPET_DURATION;
                  } else if (computedSnippetEnd < snippetEnd) {
                    startValue = Math.min(
                      computedSnippetEnd - MINIMUM_SNIPPET_DURATION,
                      durationMinusMINDURATION,
                    );
                  }
                }

                setSnippetStart(Math.min(Math.max(startValue, 0), durationMinusMINDURATION));
                setSnippetEnd(Math.min(Math.max(endValue, MINIMUM_SNIPPET_DURATION), duration));
              }
            }}
          >
            <Slider.Range className="absolute rounded-full bg-transparent" />
            {!isSongShorterThanSnippet && (
              <Slider.Thumb
                className="absolute -bottom-[14px] block outline-none focus:outline-none"
                key="snippet_start"
              >
                <View className="absolute flex items-end bg-transparent">
                  <View className="absolute flex items-end bg-transparent">
                    <View className="absolute left-[-10px] flex h-[53px] w-[21px] items-center justify-center rounded-sm bg-vault_accent" />
                  </View>
                </View>
              </Slider.Thumb>
            )}
            {!isSongShorterThanSnippet && (
              <Slider.Thumb
                className="absolute -bottom-[14px] block outline-none focus:outline-none"
                key="snippet_end"
              >
                <View className="absolute flex items-end bg-transparent">
                  <View className="absolute left-[-10px] flex h-[53px] w-[21px] items-center justify-center rounded-sm bg-vault_accent" />
                </View>
              </Slider.Thumb>
            )}
          </Slider.Root>
        </View>
      </>
    );
  },
);
