import {
  useBottomsheetContainer,
  useExtraBottomsheetContainer,
} from '../../contexts/BottomsheetContext';
import type { MembershipConfirmationBottomsheetProps } from '../../types/bottomsheetTypes';
import { View } from '../common/View';
import { closeFullScreen } from '../main/AudioPlayer';
import { MembershipConfirmationView } from '../views/MembershipView';

export function MembershipConfirmationBottomsheet({
  vaultId,
  isLoading,
  artistHandle,
  artistName,
  imageUrl,
  loggedInUserUsername,
  loginStatus,
  inviteCode,
  smsCampaignResponseShortcode,
  sourceReleaseCampaignId,
  onConfirm,
}: MembershipConfirmationBottomsheetProps) {
  const { closeBottomsheet } = useBottomsheetContainer();
  const { closeExtraBottomsheet } = useExtraBottomsheetContainer();

  const artist =
    artistHandle && artistName
      ? {
          linkValue: artistHandle,
          name: artistName,
          membershipImageUrl: imageUrl,
        }
      : undefined;

  return (
    <View className="flex flex-col items-center justify-center">
      <MembershipConfirmationView
        vaultId={vaultId}
        isLoading={isLoading}
        artist={artist}
        loggedInUserUsername={loggedInUserUsername}
        loginStatus={loginStatus}
        inviteCode={inviteCode}
        smsCampaignResponseShortcode={smsCampaignResponseShortcode}
        sourceReleaseCampaignId={sourceReleaseCampaignId}
        onComplete={() => {
          onConfirm?.();
          closeExtraBottomsheet();
          closeBottomsheet();
          closeFullScreen();
        }}
        withVaultTheme
        isModal
      />
    </View>
  );
}
