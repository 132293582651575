import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import millify from 'millify';
import { Link } from 'react-router-dom';
import {
  faCalendarDays,
  faReceipt,
  faWaveformLines,
} from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faCheckCircle } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  type ArtistEventFragment,
  ArtistMembershipReceiptTypenames,
  type FragmentType,
  getFragment,
  RsvpEventItemFragmentDoc,
  RsvpEventStatus,
} from '../../../graphql/generated';
import { useBetterGate } from '../../../hooks/useFeatureGate';
import { useOwnedArtist } from '../../../hooks/useOwnedArtist';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { Image } from '../../common/Image';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

export const RsvpEventItem = React.memo(
  ({
    eventId,
    rsvpEvent,
    actions,
  }: {
    eventId: string;
    rsvpEvent: FragmentType<RsvpEventItemFragmentDoc>;
    actions: ArtistEventFragment['actions'];
  }) => {
    const exposeCampaignDataEnabled = useBetterGate('EXPOSE_CAMPAIGN_DATA') === 'enabled';

    const rsvpFragment = getFragment(RsvpEventItemFragmentDoc, rsvpEvent);

    const now = useMemo(() => new Date().toISOString(), []);

    if (
      rsvpFragment.__typename !== 'PublicRsvpEvent' &&
      rsvpFragment.__typename !== 'RsvpEventPrivateInfo'
    ) {
      return null;
    }

    const {
      title,
      linkValue,
      status,
      coverImage,
      artist: { isAuthUserAdmin, linkValue: artistHandle, profileImage },
      eventDate,
    } = rsvpFragment;

    const receiptCount =
      rsvpFragment.__typename === 'RsvpEventPrivateInfo' ? rsvpFragment.receiptCount : 0;
    const rsvpImage = coverImage?.rsvpCoverImageUrl ?? profileImage?.artistFullProfileImageUrl;

    const ownedArtist = useOwnedArtist({ artistHandle });
    const isOwner = !!ownedArtist;

    const isActive = status === RsvpEventStatus.Active && (eventDate == null || eventDate > now);

    const hasRSVPd =
      !!actions.find(
        v => v.receiptType === ArtistMembershipReceiptTypenames.ArtistMembershipRsvpEventReceipt,
      )?.authUserCompleted && !isOwner;

    return (
      <Link
        to={`${artistNavigationPath(
          artistHandle,
          isAuthUserAdmin ? `/event/insights/${eventId}` : `/d/${linkValue}`,
        )}`}
        className="flex w-full flex-row items-center justify-between gap-4 no-underline transition-all duration-200 ease-in hover:opacity-80"
      >
        <View className="flex h-16 max-w-[80%] justify-start gap-4">
          {rsvpImage ? (
            <Image
              src={rsvpImage}
              alt={title}
              height={64}
              width={64}
              className="aspect-square h-16 w-16 flex-shrink-0 rounded-md bg-neutral500 object-cover"
            />
          ) : (
            <View className="flex aspect-square w-16 flex-shrink-0 items-center justify-center rounded-md outline outline-1 outline-vault_text/10">
              <FontAwesomeIcon icon={faWaveformLines} className="text-[20px] text-vault_text" />
            </View>
          )}
          <View className="flex w-full min-w-0 flex-col justify-center gap-2">
            <View className="w-full min-w-0">
              <Text className="truncate font-title text-[18px]/[20px] font-medium text-vault_text">
                {title}
              </Text>
            </View>
            <View className="flex flex-col gap-0.5">
              <span className="flex items-center gap-1 font-base text-[14px]/[16px] font-normal">
                {hasRSVPd ? (
                  <span className="flex items-center gap-1 font-base text-[14px]/[16px] font-normal">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-vault_accent" />
                    <Text className="font-base text-vault_accent">RSVP'd</Text>
                  </span>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCalendarDays} className="text-vault_text/60" />
                    <Text className="mt-[2px] font-base text-vault_text/60">
                      RSVP {!isActive ? '• Ended' : ''}
                    </Text>
                  </>
                )}
              </span>
            </View>
          </View>
        </View>

        {exposeCampaignDataEnabled && isOwner && (
          <View className="flex flex-shrink-0 items-center gap-1">
            <Text className="font-base text-[14px]/[18px] font-normal text-vault_text/60">
              {millify(receiptCount)}
            </Text>
            <FontAwesomeIcon icon={faReceipt} className="text-[14px] text-vault_text/60 " />
          </View>
        )}
      </Link>
    );
  },
);

RsvpEventItem.displayName = 'RsvpEvent';
