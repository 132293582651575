import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { ROUTES } from '../../constants/routeConstants';
import { useQuery } from '../../graphql/client';
import { ArtistByHandleDocument } from '../../graphql/generated';
import { useCurrentArtistHandle } from '../../hooks/useArtistHandle';
import { ArtistProfileImage } from '../artist/ArtistProfileImage';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { Logo } from '../svg/Logo';

export function MenuLogo({ withVaultTheme }: { withVaultTheme: boolean }) {
  const { artistHandle } = useCurrentArtistHandle();

  const { data: artist } = useQuery(ArtistByHandleDocument, {
    staleTime: Infinity,
    variables: !!artistHandle && { link: artistHandle },
    select(data) {
      return data.data.artistLink?.artist;
    },
  });

  return (
    <View className="mb-2 max-w-8 pl-3 md2:my-3 [&_svg]:w-12">
      <Link
        className="flex cursor-pointer appearance-none items-center gap-3 border-none bg-transparent no-underline outline-none"
        to={ROUTES.LANDING_PAGE}
      >
        {artist ? (
          <>
            <ArtistProfileImage
              className="h-12 w-12 flex-shrink-0"
              profileImageUrl={artist.profileImage.artistSmallProfileImageUrl}
            />
            <Text
              className={twMerge(
                'hidden font-title text-[18px] font-medium md2:block',
                withVaultTheme ? 'text-vault_text' : 'text-white',
              )}
            >
              {artist.name}
            </Text>
          </>
        ) : (
          <>
            <Logo
              className="h-12 w-12 flex-shrink-0"
              variant={withVaultTheme ? 'themed' : 'white'}
            />
            <Text
              className={twMerge(
                'hidden font-title text-[18px] font-medium md2:block',
                withVaultTheme ? 'text-vault_text' : 'text-white',
              )}
            >
              Vault
            </Text>
          </>
        )}
      </Link>
    </View>
  );
}
