import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { Text } from './Text';
import { View } from './View';

export function IconBulletRow({
  icon,
  text,
  withVaultTheme,
}: {
  icon: IconProp;
  text: string;
  withVaultTheme: boolean;
}) {
  return (
    <View className="flex items-center gap-4">
      <FontAwesomeIcon
        icon={icon}
        className={twMerge('text-[20px]', withVaultTheme ? 'text-vault_text' : 'text-white')}
      />
      <Text
        className={twMerge(
          'text-left font-base text-[16px]/[20px]',
          withVaultTheme ? 'text-vault_text/50' : 'text-white/50',
        )}
      >
        {text}
      </Text>
    </View>
  );
}
